import SideNavTreeMenuAccess from "../../../components/sidenav-tree-menu-access";

export default {
  name: "MMappingMenuAccess",
  components: {
    SideNavTreeMenuAccess,
  },
  data() {
    return {
      root: [],
      root_source: [],
      property: {
        filterDto: {
          userRoleId: "",
          menuAccessName: "",
          menuAccessSort: "",
        },
        listElement: {
          menuAccess: {
            downloading: false,
            message: "",
          },
        },
        animation: {
          mappingMenuAccess: {
            isLoading: false,
          },
        },
      },
      options: {
        menuAccess: {
          userRole: [],
        },
      },
    };
  },
  methods: {
    findMenuAccessByName() {
      if (!this.property.filterDto.menuAccessName) {
        this.searchUserRoleMenuAccessDataByRoleId(
          this.property.filterDto.userRoleId
        );
        return;
      }
      var element = this.root_source;
      var menuAccessName = this.property.filterDto.menuAccessName;
      var result = this.searchTreeByName(element, menuAccessName);
      if (result) {
        this.property.listElement.menuAccess.downloading = true;
        this.property.listElement.menuAccess.message = "";
        this.root = [];
        setTimeout(() => {
          this.property.listElement.menuAccess.downloading = false;
          this.root = result;
        }, 1500);
      }
    },
    findMenuAccessByCode() {
      if (!this.property.filterDto.menuAccessSort) {
        this.searchUserRoleMenuAccessDataByRoleId(
          this.property.filterDto.userRoleId
        );
        return;
      }
      var element = this.root_source;
      var menuAccessSort = this.property.filterDto.menuAccessSort;
      var result = this.searchTreeByCode(element, menuAccessSort);
      if (result) {
        this.property.listElement.menuAccess.downloading = true;
        this.property.listElement.menuAccess.message = "";
        this.root = [];
        setTimeout(() => {
          this.property.listElement.menuAccess.downloading = false;
          this.root = result;
        }, 1500);
      }
    },
    searchTreeByName(element, matchingTitle) {
      let nodes = [];
      let index = 0;
      while (element.length !== index) {
        var stack = [],
          node,
          ii;
        stack.push(element[index]);
        while (stack.length > 0) {
          node = stack.pop();
          console.log(node);
          if (node.name.toLowerCase().match(matchingTitle.toLowerCase())) {
            nodes.push(node);
          } else if (node.accessModules && node.accessModules.length) {
            for (ii = 0; ii < node.accessModules.length; ii += 1) {
              stack.push(node.accessModules[ii]);
            }
          }
        }
        index++;
      }
      return nodes;
    },
    searchTreeByCode(element, matchingTitle) {
      let nodes = [];
      let index = 0;
      while (element.length !== index) {
        var stack = [],
          node,
          ii;
        stack.push(element[index]);
        while (stack.length > 0) {
          node = stack.pop();
          console.log(node);
          if (node.code.toLowerCase().match(matchingTitle.toLowerCase())) {
            nodes.push(node);
          } else if (node.accessModules && node.accessModules.length) {
            for (ii = 0; ii < node.accessModules.length; ii += 1) {
              stack.push(node.accessModules[ii]);
            }
          }
        }
        index++;
      }
      return nodes;
    },
    async submitMenuAccess(payload) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "user-management",
          reqUrl:
            "user-role/mapping-menu-access/v2/" +
            this.property.filterDto.userRoleId,
          payload: {
            menuId: payload.menuId,
            granted: payload.granted,
          },
        });
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.$buefy.toast.open({
              message:
                payload.granted === false
                  ? "Menu Berhasil Di Non-Aktifkan"
                  : "Menu Berhasil Di Aktifkan",
              type: "is-success",
              duration: 2000,
              position: "is-top",
              queue: false,
            });
          }, 500);
        } else {
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => {
                this.searchUserRoleMenuAccessDataByRoleId(
                  this.property.filterDto.userRoleId
                );
              },
            });
          }, 500);
        }
      } catch (error) {
        console.log(error.response);
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => {
              this.searchUserRoleMenuAccessDataByRoleId(
                this.property.filterDto.userRoleId
              );
            },
          });
        }, 500);
      } finally {
        setTimeout(() => {}, 1500);
      }
    },
    handleCatchErrorMappingMenuAccess(error) {
      console.log(error.response);
      this.root = [];
      this.root_source = [];
      this.property.listElement.menuAccess.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async searchUserRoleMenuAccessDataByRoleId(event) {
      this.property.listElement.menuAccess.downloading = true;
      this.property.listElement.menuAccess.message = "";
      this.property.filterDto.menuAccessSort = "";
      this.property.filterDto.menuAccessName = "";
      this.root = [];
      this.root_source = [];
      if (event === "") {
        this.property.listElement.menuAccess.downloading = false;
        this.root = [];
        this.root_source = []
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "user-management",
            reqUrl: "menu-access/v2",
            payload: {
              roleId: event,
            },
          });
          if (resp.data.code === "SUCCESS") {
            setTimeout(() => {
              this.root = resp.data.data;
              this.root_source = resp.data.data;
            }, 1000);
          } else {
            setTimeout(() => {
              this.root = [];
              this.root_source = [];
              this.property.listElement.menuAccess.message = resp.data.message;
            }, 1000);
          }
        } catch (error) {
          this.handleCatchErrorMappingMenuAccess(error);
        } finally {
          setTimeout(() => {
            this.property.listElement.menuAccess.downloading = false;
          }, 1000);
        }
      }
    },
    helperGetRoleDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          params: {
            name: "",
            page: 0,
          },
          url: "user-role",
        })
        .then((resp) => {
          this.options.menuAccess.userRole.push({
            value: "",
            text: "-- Pilih --",
          });
          resp.data.data.content.map((index) => {
            const text = index.userRoleName;
            const value = index.userRoleId;
            this.options.menuAccess.userRole.push({ text, value });
          });
        });
    },
  },
  mounted() {
    this.helperGetRoleDataForReference();
    this.property.listElement.menuAccess.message = "Pilih Role Terlebih Dahulu";
  },
};
