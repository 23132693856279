export default {
  name: "SideNavTreeMenuAccess",
  props: {
    nodes: Array,
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      expanded: [],
    };
  },
  methods: {
    // findMenuAccessByName(value) {
    //   this.node
    // },
    submitMenuAccess(data) {
      if (this.depth == 0) {
        this.$emit("chooseMenuAccess", data);
        return;
      }
      this.$emit("sendToParent", data);
    },
    isExpanded(node) {
      return this.expanded.indexOf(node) !== -1;
    },
    injectingExpanded(data) {
      this.expanded = data;
    },
    nodeClicked(node) {
      if (node.url) {
      }
      if (!this.isExpanded(node)) {
        this.expanded.push(node);
      } else {
        this.expanded.splice(this.expanded.indexOf(node));
      }
    },
    defaultOpenAll() {
      setTimeout(() => {
        this.nodes.map((index) => {
          this.nodeClicked(index);
        });
      }, 1000);
      // setTimeout(() => {
      //   this.nodeClicked(this.nodes);
      // }, 500);
    },
  },
  components: {},
  computed: {},
  mounted() {
    this.defaultOpenAll();
  },
};
